import React, { useState, useEffect } from 'react';
import { CardElement, useStripe, useElements } from '@stripe/react-stripe-js';
import axios from 'axios';
import 'react-phone-number-input/style.css';
import PhoneInput, { isValidPhoneNumber } from 'react-phone-number-input';
import styled from 'styled-components';
import { useCookies } from 'react-cookie';

// Styled components for custom styling
const StyledPhoneInput = styled(PhoneInput)`
  .PhoneInputCountry {
    position: absolute;
    left: 10px;
    top: 50%;
    transform: translateY(-50%);
    z-index: 2;
    pointer-events: auto;
  }

  .PhoneInputInput {
    padding-left: 60px; // Increase this padding to shift text to the right
  }
`;

const DisclaimerText = styled.div`
  margin-top: 40px;
  font-size: 10px;
  color: grey;
`;

function PaymentForm() {
    const [cookies, setCookie] = useCookies(['cxId', 'cvId']);  // Use cookies hook
    const [cxId, setCxId] = useState('');
    const [cvId, setCvId] = useState('');
    const stripe = useStripe();
    const elements = useElements();
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [phone, setPhone] = useState('');
    const [isSubmitting, setIsSubmitting] = useState(false); // State to handle form submission status
    const [errorMessage, setErrorMessage] = useState(''); // State for error message

    // Handle cxId and cvId from URL or cookies
    useEffect(() => {
        const searchParams = new URLSearchParams(window.location.search);
        const urlCxId = searchParams.get('cxId');
        const urlCvId = searchParams.get('cvId');
        if (urlCxId) {
            setCxId(urlCxId);
            setCookie('cxId', urlCxId, { path: '/', maxAge: 604800 }); // 7 days expiration
        } else {
            setCxId(cookies.cxId || '');
        }
        if (urlCvId) {
            setCvId(urlCvId);
            setCookie('cvId', urlCvId, { path: '/', maxAge: 604800 }); // 7 days expiration
        } else {
            setCvId(cookies.cvId || '');
        }
    }, [setCookie, cookies.cxId, cookies.cvId]);

    const handleSubmit = async (event) => {
        event.preventDefault();
        setIsSubmitting(true);

        if (!stripe || !elements) {
            console.log('Please ensure all fields are filled.');
            alert('All fields must be filled.');
            setIsSubmitting(false);
            return;
        }

        const cardElement = elements.getElement(CardElement);
        const { error, paymentMethod } = await stripe.createPaymentMethod({
            type: 'card',
            card: cardElement,
            billing_details: {
                name,
                email,
                phone,
            },
            metadata: { cxId, cvId }, // Add cxId and cvId as metadata
        });

        if (error) {
            console.error('Error:', error);
            alert(error.message);
            setIsSubmitting(false);
            return;
        }

        const last4 = paymentMethod.card.last4; // Extract the last 4 digits of the card

        try {
            const response = await axios.post('https://api.betterfeelingtoday.com/payment/setup-payment/', {
                name,
                email,
                phone,
                payment_method_id: paymentMethod.id,
                cxId,  // Send cxId to your server
                cvId   // Send cvId to your server
            });

            const { client_secret } = response.data;

            // Confirm the SetupIntent to handle SCA
            const { setupIntent, error: confirmError } = await stripe.confirmCardSetup(client_secret, {
                payment_method: paymentMethod.id
            });

            if (confirmError) {
                console.error('Error:', confirmError);
                alert(confirmError.message);
                setIsSubmitting(false);
                return;
            }

            console.log('Setup result:', setupIntent);

            // Send note in chat for agent to know that verification was successful
            const noteTxt = `AGEVERIFY SUCCESS: Customer ${name} passed age verification and authorized card for future payment. Last 4 digits of the card used: ${last4}.`;
            await axios.post('https://api.betterfeelingtoday.com/messages/send/note/', {
                note_txt: noteTxt,
                conversation_id: cvId
            }, {
                headers: {
                    'Content-Type': 'application/json'
                }
            });

            // Feed customer information into chatwoot
            await axios.post('https://api.betterfeelingtoday.com/contacts/update/from_checkout', {
                cxId,
                email,
                phone,
                name
            }, {
                headers: {
                    'Content-Type': 'application/json'
                }
            });

            // Redirect to thank you page with back button to either WhatsApp or Web Chat
            alert('Age successfully verified. Please return to chat window!');
            setIsSubmitting(false);

        } catch (error) {
            console.error('Error:', error.response?.data || error.message);

            // Send note to agent that verification failed
            const noteTxt = `AGEVERIFY FAILED: Customer ${name} failed age verification and didn't authorize card for future payment. Last 4 digits of the card used: ${last4}. Please assist customer and ask them to try with a different card.`;
            await axios.post('https://api.betterfeelingtoday.com/messages/send/note/', {
                note_txt: noteTxt,
                conversation_id: cvId
            }, {
                headers: {
                    'Content-Type': 'application/json'
                }
            });

            // Feed customer information into chatwoot
            await axios.post('https://api.betterfeelingtoday.com/contacts/update/from_checkout', {
                cxId,
                email,
                phone,
                name
            }, {
                headers: {
                    'Content-Type': 'application/json'
                }
            });

            // Show error message above button and ask to retry
            setErrorMessage('Age verification failed. Please correct card details or try again with another card.');
            setIsSubmitting(false);
        }
    };

    return (
        <div className="payment-form-container">
            <div className="card">
                <h2 className="card-header">FREE 1-Hour Mentoring</h2>
                <h3 className="card-subheader">Start your free session instantly. <br /> Your information is secure and confidential.</h3>
                <form onSubmit={handleSubmit} className="payment-form">
                    <div className="input-group">
                        <input type="text" value={name} onChange={e => setName(e.target.value)} placeholder="Full Name" required />
                    </div>
                    <div className="input-group">
                        <input type="email" value={email} onChange={e => setEmail(e.target.value)} placeholder="Email" required />
                    </div>
                    <div style={{ position: 'relative' }}>
                        <StyledPhoneInput
                            international
                            value={phone}
                            onChange={setPhone}
                            placeholder="Phone Number"
                            defaultCountry="GB"
                            error={phone ? (isValidPhoneNumber(phone) ? undefined : 'Invalid phone number') : 'Phone number required'}
                        />
                    </div>
                    <div className="patient-data-policy">
                        <span>In line with our Patient Data Policy, please register your credit card to confirm that you are of legal age.</span>
                        <span className="bold-green"> Rest assured, there will be no charge for this free session.</span>
                    </div>
                    <div className="input-group">
                        <CardElement options={{
                            style: {
                                base: {
                                    fontSize: '16px',
                                    color: '#424770',
                                    '::placeholder': {
                                        color: '#aab7c4',
                                    },
                                },
                                invalid: {
                                    color: '#9e2146',
                                },
                                focus: {
                                    border: '2px solid #289D57',  // Set border style for focus state
                                }
                            },
                        }} />
                    </div>
                    <div className="input-group">
                        <img src="/trust1.png" alt="Trustpilot" className="responsive-image" />
                    </div>

                    <div className="custom-text-container">
                        <span>By clicking "Start Your FREE Session", I confirm that I have read, consented to, and accepted the
                            <a href="https://betterfeelingtoday.com/terms-and-conditions/" target="_blank" rel="noopener noreferrer"> Terms and Conditions</a> and the
                            <a href="https://betterfeelingtoday.com/privacy-policy/" target="_blank" rel="noopener noreferrer"> Privacy Policy</a>.</span>
                    </div>

                    <div className="error-message-container">
                        <span>
                            {errorMessage && <span>{errorMessage}</span>}
                        </span>
                    </div>

                    <button type="submit" className="submit-btn" disabled={isSubmitting}>Start Your FREE Session</button>
                </form>
                <DisclaimerText>
                    Your credit card helps us to determine your geographical location and your age, thereby we will be able to offer you the appropriate membership access and help needed. We will not charge your card during the 7-days free trial subscription.
                    A pre-authorization amount can be applied at the time of registration. At the end of the free subscription period, your membership will automatically become a paid subscription that will charge the amount of GBP 49 per week, unless canceled. The subscription automatically renews every week until you cancel it. All your billing information will be available in the members area and also in your welcome email.
                </DisclaimerText>
            </div>
        </div>
    );
}

export default PaymentForm;
